:root {
  --clr-base-dk: #04254e;
  --clr-base-dk-rgb: 4, 37, 78;
  --clr-accent-dk: #404040;
  --clr-base-lt: #f3f3f3;
  --clr-base-lt-rgb: 243, 243, 243;
  --clr-accent-lt: #ccc;
  --clr-primary: #5954a4;
  --clr-primary-rgb: 89, 84, 164;
  --clr-background-html: #04254e;
  --clr-background-dk: #04254e;
  --clr-background-dk-rgb: 4, 37, 78;
  --clr-background-lt: #f3f3f3;
  --clr-background-lt-rgb: 243, 243, 243;
  --clr-background-accent-dk: #404040;
  --clr-background-accent-dk-rgb: 64, 64, 64;
  --clr-background-accent-lt: #ccc;
  --clr-background-accent-lt-rgb: 204, 204, 204;
  --clr-text-dk: #f3f3f3;
  --clr-text-dk-rgb: 243, 243, 243;
  --clr-text-lt: #04254e;
  --clr-text-lt-rgb: 4, 37, 78;
  --clr-intro-title-dk: #f3f3f3;
  --clr-intro-title-lt: #04254e;
  --clr-title-dk: #f3f3f3;
  --clr-title-lt: #5954a4;
  --clr-link-html: #5954a4;
  --clr-link-hover-html: #04254e;
  --clr-link-dk: #5954a4;
  --clr-link-lt: #5954a4;
  --clr-link-hover-dk: #f3f3f3;
  --clr-link-hover-lt: #04254e;
  --clr-btn-primary: #5954a4;
  --clr-btn-dk: #04254e;
  --clr-btn-lt: #f3f3f3;
  --clr-btn-txt-dk: #04254e;
  --clr-btn-txt-lt: #f3f3f3;
  --clr-btn-background-hs: #5954a4;
  --clr-btn-hover-background-hs: #04254e;
  --clr-btn-txt-hs: #f3f3f3;
  --clr-btn-hover-txt-hs: #f3f3f3;
  --clr-txt-nav-dk: #04254e;
  --clr-txt-nav-dk-rgb: 4, 37, 78;
  --clr-txt-nav-lt: #f3f3f3;
  --clr-txt-nav-lt-rgb: 243, 243, 243;
  --opc-nav-hover: 0.66;
  --opc-footer-hover: 0.8;
  --clr-nav-hover-background: #5954a4;
  --clr-nav-hover-txt: #f3f3f3;
  --clr-hover-box-txt: #f3f3f3;
  --clr-hover-box-background: #5954a4;
}

body {
  overflow: hidden;
}

.license-key-background {
  background-image: url(https://vodium.com/hubfs/Brand/Gradient-80-Opacity.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 100vmin;
}
.darkMode {
  background-color: #04254E;
}
.lightMode {
  background-color: #F2F2F2;
}

.license-key {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 70px;
}

.logo {
  width: 100px;
  height: 100px;
}

h2 {
  margin-top: 5px;
}
img {
  margin: 20px;
}

input {
  width: 350px;
  height: 42px;
  padding: 0 6px;
  margin-left: 13px;
  font-size: 18px;
  color: #000;
  background: #e8f0fe;
  border: 1px solid rgba(2, 23, 49, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
}
.input-box {
  padding: 7px 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  transition: border-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.input-box.invalid {
  border-color: red;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 100px;
  margin-top: 15px;
}
p.pstyle2 {
  margin: 15px;
  font-weight: 450;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.custom-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
p.pstyle{
  margin-top: 8px;
}
.custom-button:hover {
  background-color: #0056b3;
}
.button-33 {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.15) 0 1px 2px, rgba(44, 187, 99, 0.15) 0 2px 4px,
    rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-33:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
button[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}

.loading {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 2px solid #000;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin-left: 5px;
  vertical-align: middle;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 230px; /* Adjust the width as needed */
  padding: 1px;
  height: 40px;
  border-radius: 5px;
}

@mixin alert($name, $bgColor) {
  $accentColor: darken($bgColor, 50);
  .#{$name} {
    background-color: #{$bgColor};
    border-left: 5px solid $accentColor;
    .close {
      border-color: $accentColor;
      color: $accentColor;
    }
  }
}

@include alert(simple-alert, #ebebeb);
@include alert(success-alert, #a8f0c6);
@include alert(danger-alert, #f7a7a3);
@include alert(warning-alert, #ffd48a);

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  position: relative;
  left: 50%;
  margin-top: 23%;
  width: 2em;
  height: 2em;
  margin-left: -1em;
  border-radius: 50%;
  background: linear-gradient(#95b2d8, #9086c2, #af88c7);
  animation: spinner 1s linear infinite;
}

.loading-spinner::before {
  position: absolute;
  content: "";
  background: #04254e;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  border-radius: 50%;
}

.loading-text {
  position: relative;
  left: 50%;
  margin-top: 1%;
  margin-left: -3em;
  font-weight: 700;
  font-size: small;
  color: #eee;
}